<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm>
                <h1>Daftar</h1>
                <p class="text-muted">
                  Buat akun kontraktor anda, kemudian admin kami akan
                  memverifikasinya.
                </p>
                <CInput placeholder="Nama Perusahaan" v-model="nama">
                  <template #prepend-content
                    ><CIcon name="cil-user"
                  /></template>
                </CInput>
                <CInput placeholder="Nama Penanggung Jawab" v-model="pic">
                  <template #prepend-content
                    ><CIcon name="cil-user"
                  /></template>
                </CInput>
                <CInput
                  placeholder="Telpon"
                  autocomplete="telpon"
                  v-model="telpon"
                >
                  <template #prepend-content
                    ><CIcon name="cil-call"
                  /></template>
                </CInput>
                <CInput
                  placeholder="Email"
                  autocomplete="email"
                  v-model="email"
                  prepend="@"
                />
                <!-- <CInput
                  placeholder="Username"
                  autocomplete="username"
                  v-model="username"
                >
                  <template #prepend-content
                    ><CIcon name="cil-user"
                  /></template>
                </CInput> -->
                <CInput
                  placeholder="Password"
                  type="password"
                  v-model="password"
                  autocomplete="new-password"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>
                <CInput
                  placeholder="Repeat password"
                  type="password"
                  v-model="repassword"
                  autocomplete="new-password"
                  class="mb-4"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>
                <CButton color="success" v-on:click="loginNow" block
                  >Daftarkan Akun</CButton
                >
              </CForm>
            </CCardBody>
            <!-- <CCardFooter class="p-4">
              <CRow>
                <CCol col="6">
                  <CButton block color="facebook"> Facebook </CButton>
                </CCol>
                <CCol col="6">
                  <CButton block color="twitter"> Twitter </CButton>
                </CCol>
              </CRow>
            </CCardFooter> -->
          </CCard>
        </CCol>
      </CRow>
    </CContainer>

    <CModal title="Warning" :show.sync="myModal2" size="sm">
      Maaf! Registrasi gagal, silahkan periksa kembali koneksi internet anda...
    </CModal>
    <CModal title="Warning" :show.sync="myModal" size="sm">
      Maaf! Data registrasi tidak blh kosong...
    </CModal>
    <CModal title="Sukses" color="success" :show.sync="mySukses" size="sm">
      Sukses! Data akun kontraktor anda berhasil diregistrasi. Kami akan memverifikasi keabsahan data terlebih dahulu sebelum digunakan.
    </CModal>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Register",
  data() {
    return {
      tabs: [],
      myModal: false,
      myModal2: false,
      mySukses: false,
      users: "",
      dismissCountDown: 0,
      pic: "",
      nama: "",
      password: "",
      repassword: "",
      email: "",
      telpon: "",
      rcvr: "",
      activeTab: 1,
    };
  },
  // mounted(){
  // // this.showUser();
  // },
  methods: {
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    loginNow: function (event) {
      // Simple POST request with a JSON body using axios
      const user = {
        nama: this.nama,
        pic: this.pic,
        email: this.email,
        telpon: this.telpon,
        password: this.password,
      };
      // alert(localStorage.getItem("jwt"));
      if (
        (this.nama == null || this.nama == "",
        this.password == null || this.password == "",
        this.email == null || this.email == "")
      ) {
        this.myModal = true;
      } else {
        if (this.password == this.repassword) {
          axios
            .post(
              "https://sippd.probowsolution.com/api/public/index.php/registerapp/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
              user
            )
            .then((response) => {
              // console.log(response);
              // alert(response.data);
              // this.tabs = response.data;/
              if (response.data == "sukses") {
                
                this.mySukses = true;
                this.$router.push("/login");

                // this.myModal2 = true;
              } else {
                // alert(JSON.stringify(user));
                this.myModal2 = true;
              }
            });
        } else {
          this.myModal = true;
        }
      }
    },
  },
};
</script>
